import React, {useState} from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet';
import Particles from 'react-tsparticles'
import options from './particle-options.json'
import {
  container,
  hamburger,
  hamburgerLine,
  navBar,
  heading,
  desktopNavLinks,
  navLinkItem,
  navLinkText,
  desktopNav,
  mobileNav,
  content,
  siteTitle,
  particles
} from './layout.module.css'

const Layout = ({ pageTitle, children, data }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMobileMenu = () => setMenuOpen(!menuOpen)
  
  return (
    <div className={container}>
      <Helmet>
        <title>{pageTitle} | {data.site.siteMetadata.title}</title>
      </Helmet>
      <section className={navBar}>
        <section className={hamburger} onClick={toggleMobileMenu}>
          <div className={hamburgerLine}></div>
          <div className={hamburgerLine}></div>
          <div className={hamburgerLine}></div>
        </section>
        <Link to="/" className={siteTitle}>{data.site.siteMetadata.title}</Link>
        <nav className={desktopNav}>
          <ul className={desktopNavLinks}>
            <li className={navLinkItem}>
              <Link to="/" className={navLinkText}>
                Home
              </Link>
            </li>
            <li className={navLinkItem}>
              <Link to="/about" className={navLinkText}>
                About
              </Link>
            </li>
            <li className={navLinkItem}>
              <Link to="/blog" className={navLinkText}>
                Blog
              </Link>
            </li>
          </ul>
        </nav>
      </section>
      { menuOpen ? 
          <nav>
             <ul className={mobileNav}>
                <li className={navLinkItem}>
                  <Link to="/" className={navLinkText}>
                    Home
                  </Link>
                </li>
                <li className={navLinkItem}>
                  <Link to="/about" className={navLinkText}>
                    About
                  </Link>
                </li>
                <li className={navLinkItem}>
                  <Link to="/blog" className={navLinkText}>
                    Blog
                  </Link>
                </li>
            </ul>
          </nav> 
          : null}
      <main className={content}>
        <h1 className={heading}>{pageTitle}</h1>
        {children}
      </main>
      <span className={particles}>
        <Particles id="particles" options={options}/>
      </span>
    </div>
  )
}

export default function (props) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => <Layout data={ data } {...props} />}
    />
  )
}

// export default Layout