import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/Layout";
import {
  listing,
  linkText
} from './blog.module.css'

const BlogPage = ({ data }) => {
  return (
    <Layout pageTitle="Log Entries">
      {data.allMdx.nodes.map((node) => (
        <article key={node.id} className={listing}>
          <h2>
            <Link to={`/blog/${node.slug}`} className={linkText}>{node.frontmatter.title}</Link>
          </h2>
          <p>Posted: {node.frontmatter.date}</p>
        </article>
      ))}
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
        }
        id
        slug
        parent {
          ... on File {
            modifiedTime(formatString: "MMMM D, YYYY")
          }
        }
      }
    }
  }
`;

export default BlogPage;
